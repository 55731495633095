.container {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  margin-bottom: 5rem;
  pointer-events: none;
}

.container::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top); */
}

.background {
  position: absolute;
  z-index: -1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 110vw;
  margin-left: -5vw;
  border-radius: 50% 50% / 50% 0 0;
}

.contentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
}

.imageContainer {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem;
  width: 25rem;
  height: auto;
}

.myImage {
  width: 25rem;
  border-radius: 8px;
  box-shadow: 6px 6px 5px rgb(240, 240, 240);
}

.aboutTextContainer {
  visibility: hidden;
  position: relative;
  z-index: -1;
  background: rgba(241, 240, 240, 0.678);
  opacity: 0.8;
  width: 30rem;
  padding: 9rem 3rem;
  box-shadow: 6px 6px 5px rgb(240, 240, 240);
  margin-left: -2rem;
  font-size: 1.2rem;
  line-height: 2rem;
  border-radius: 8px;
}

.aboutTitle {
  margin-bottom: 1.5rem;
}

.animationTrigger {
  position: absolute;
  top: 50%;
}

@media only screen and (max-width: 600px) {
  .contentContainer {
    flex-direction: column;
  }

  .myImage {
    width: 70vw;
    border-radius: 8px;
  }
  .aboutTextContainer {
    visibility: hidden;
    position: relative;
    z-index: -1;
    background: rgba(241, 240, 240, 0.678);
    opacity: 0.8;
    width: 100vw;
    text-align: center;
    padding: 5rem 2rem;
    box-shadow: 6px 6px 5px rgb(240, 240, 240);
    margin-left: 0rem;
    font-size: 1.2rem;
    line-height: 2rem;
    border-radius: 8px;
  }
}
