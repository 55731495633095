.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  margin: 2rem;
  border-radius: 10px;
  width: 80%;
  margin: -15rem auto 3rem auto;
  background: white;
  box-shadow: 1px 1px 4px lightgray;
}

.skillContainer {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  flex-wrap: wrap;
  justify-content: stretch;
  height: 100%;
}

.skillContainer:nth-child(2) {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.icon {
  color: #3282b8;
  font-size: 3.5rem;
  margin: 3rem 0 2rem 0;
}

.heading {
  color: #3282b8;
  font-family: "Dancing Script", sans-serif;
  font-size: 1.8rem;
}

.description {
  margin-top: 2rem;
  text-align: center;
}

.subheading {
  margin-top: 2rem;
  color: #3282b8;
}

.loveTo {
  margin-top: 1.5rem;
  text-align: center;
}

.tools {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 1rem auto 3rem auto;
    flex-direction: column;
  }

  .skillContainer:nth-child(2) {
    border: none;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
}
