.head {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    #c8d2ff,
    rgba(255, 255, 255, 0.274),
    #d3dbff00
  );
  padding: 2rem 4rem;
  overflow: hidden;
  margin-bottom: 5.4rem;
}

.siteControlButtonContainer {
  position: fixed;
  right: 3rem;
  bottom: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}

.siteControlButton {
  margin: 1.2rem;
  font-size: 1.5rem;
  color: #3282b8;
  cursor: pointer;
  opacity: 0.3;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  box-shadow: inset 0 0 3px;
  padding: 0.7rem 0.8rem;
  transition: all 0.4s ease-out;
}

.siteControlHide {
  opacity: 0;
  pointer-events: none;
}

.siteControlButton:hover {
  opacity: 1;
}

.head-background-image {
  position: absolute;
  z-index: -1000;
  object-fit: cover;
  left: -5px;
  width: 105%;
  height: 100%;
  top: 8rem;
  opacity: 0.5;
}

@media only screen and (max-width: 600px) {
  .head {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(
      to bottom,
      #c8d2ff,
      rgba(255, 255, 255, 0.274),
      #d3dbff00
    );
    padding: 2rem 1rem;
    overflow: hidden;
    margin-bottom: 5.4rem;
  }

  .siteControlButtonContainer {
    right: 0rem;
  }
}
