.me-container {
  margin-top: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  pointer-events: none;
}

.container {
  padding: 5rem 12rem;
  /* color: rgb(22, 125, 143); */
  color: #45b29a;
  line-height: 3.8rem;
}

.hi {
  font-family: "Dancing Script", sans-serif;
  font-size: 4rem;
}

.name {
  font-size: 2rem;
  letter-spacing: 0.15rem;
}

.role-container p {
  position: relative;
  font-family: "Dancing Script", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  overflow: hidden;
}
.role-container p span.typed-text {
  font-weight: normal;
  color: #dd7732;
}
.role-container p span.cursor {
  display: inline-block;
  position: absolute;
  bottom: 5px;
  background-color: #dd7732;
  margin-left: 0.2rem;
  width: 25px;
  height: 4px;
  animation: blink 1s infinite;
}
.role-container p span.cursor.typing {
  animation: none;
}
@keyframes blink {
  0% {
    background-color: #dd7732;
  }
  49% {
    background-color: #dd7732;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #dd7732;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 1rem 2rem;
    text-align: center;
    color: #45b29a;
    line-height: 3.8rem;
  }
}
