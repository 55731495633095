.container {
  margin: 5rem auto 3rem auto;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  color: #3282b8;
  font-family: "Dancing Script", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
