* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  border-left: 1px solid #e6ecf8;
}

::-webkit-scrollbar-thumb {
  background-color: #141c3a;
}
