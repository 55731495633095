.divider {
  border: dotted #eaf6f6 6px;
  border-bottom: none;
  width: 6%;
  margin: 2rem auto 3rem auto;
}

@media only screen and (max-width: 600px) {
  .divider {
    width: 15%;
  }
}
