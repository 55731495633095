.navbar {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  padding: 0 6rem;
  justify-content: space-between;
  align-items: center;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid lightgray;
}

.brand-name {
  color: rgb(71, 110, 184);
  font-size: 2.2rem;
  margin-left: 2rem;
  cursor: pointer;
}

.brand-name::first-letter {
  font-family: "Dancing Script", sans-serif;
}

.brand-name:hover {
  text-decoration: underline;
}

.nav-items {
  display: flex;
}

.nav-items > div {
  text-decoration: none;
  color: rgb(71, 110, 184);
  display: inline-block;
  position: relative; /* prepare the position for sliding in and out */
  margin: 0 2rem;
  font-size: 1.3rem;
  cursor: pointer;
}
/* add a empty string before the elment with class .slide-in-out  */
.nav-items > div:before {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  transition: width 0s ease, background-color 0.5s ease;
  -webkit-transition: width 0s ease, background-color 0.5s ease;
  -moz-transition: width 0s ease, background-color 0.5s ease;

  /* position the content to the left bottom corner of the parent element to make it to slide in from left to right on hover */
  position: absolute;
  left: 0;
  bottom: 0;
}
/* add a empty string after the elment with class .slide-in-out  */
.nav-items > div:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: white;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease, background-color 0.5s ease;
  -moz-transition: width 0.5s ease, background-color 0.5s ease;

  /* position the content to the left bottom corner of the parent element to make it to slide out from left to right on hover */
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Change the width and background on hover, aka sliding in and out */
.nav-items > div:hover:before {
  width: 100%;
  background: white;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
}
.nav-items > div:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
  -webkit-transition: width 0s ease;
  -moz-transition: width 0s ease;
}

.sidebarToggle {
  color: rgb(71, 110, 184);
  font-size: 3rem;
  display: none;
  cursor: pointer;
  margin-right: 1rem;
}

.sidebar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: -50%;
  z-index: 4;
  height: 100vh;
  width: 50%;
  background: #3282b8;
  transition: all 0.6s ease-out;
}

.sidebar-on {
  left: 0;
  box-shadow: 1px 4px 5px;
}

.sidebarItem {
  width: 100%;
  cursor: pointer;
  font-size: 2rem;
  text-align: center;
  padding: 3rem 0;
  color: white;
  transition: 0.2s ease-out;
}

.sidebarItem:hover {
  background: white;
  color: #3282b8;
}

@media only screen and (max-width: 900px) {
  .navbar {
    padding: 0 0rem;
    justify-content: space-between;
    align-items: center;
  }

  .nav-items {
    display: none;
  }

  .sidebar {
    width: 80%;
    left: -80%;
  }

  .sidebar-on {
    left: 0;
    box-shadow: 1px 4px 5px;
  }

  .sidebarToggle {
    display: block;
  }

  .logo {
    margin-left: 0rem;
  }
}

@media only screen and (min-width: 900px) {
  .sidebar-on {
    left: -80%;
    box-shadow: 1px 4px 5px;
  }
}
