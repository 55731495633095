.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 17rem;
  border: 2px solid lightgray;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 2px 2px 3px lightgray;
  margin: 0 1rem 3rem 1rem;
  overflow: visible;
}

.image {
  border-radius: 20px;

  width: 19.7rem;
  height: 11rem;
  object-fit: cover;
  opacity: 0.8;
  transition: all 1s ease-out;
}

.image:hover {
  transform: scale(2.5);
  transform-origin: bottom;
  object-fit: initial;
  opacity: 1;
  z-index: 1;
  border: 0.1px solid lightgray;
  border-radius: 10px;
}

.imageText {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #3282b8;
  text-align: center;
}

.controlContainer {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous {
  cursor: pointer;
  color: #3282b8;
  margin: 0 1rem;
  font-size: 1.5rem;
}

.control {
  cursor: pointer;
  color: #3282b8;
  margin: 0 1rem;
  font-size: 1.5rem;
}

.controlHidden {
  pointer-events: none;
  visibility: hidden;
}

.confidential {
  margin-top: 1rem;
  text-align: center;
  color: lightgray;
}

@media only screen and (max-width: 600px) {
  .image:hover {
    transform: scale(1.6);
    transform-origin: bottom;
    object-fit: initial;
    opacity: 1;
    z-index: 1;
    border: 0.1px solid lightgray;
    border-radius: 10px;
  }
}
