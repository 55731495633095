.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3282b8;
  margin-top: 10rem;
  padding-bottom: 4rem;
}

.contactBanner {
  width: 80%;
  padding: 4rem 0;
  margin: -5rem auto 3rem auto;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background: #011e31;
  color: white;
}

.getStarted {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  pointer-events: none;
}

.hireMe {
  pointer-events: none;
  text-align: center;
}

.connectButton {
  cursor: pointer;
  color: white;
  padding: 1.2rem 2rem;
  font-weight: 400;
  border-width: 2px;
  background-color: transparent;
  border-color: #7510f7;
  border-radius: 290486px;
  font-size: 1.25rem;
  transition: all 0.3s ease-out;
  outline: none;
}

.connectButton:hover {
  background: #3282b8;
  color: white;
  border-color: #011e31;
}

.quote {
  pointer-events: none;
  text-align: center;
  font-size: 1.1rem;
  color: aliceblue;
}

.socialIconContainer {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.socialIcon {
  margin: 0 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 2px lightgray;
  padding: 0.7rem 0.8rem;
  transition: all 0.4s ease-out;
}

.socialIcon:hover {
  cursor: pointer;
  color: #3282b8;
  background: white;
}

.crafted {
  pointer-events: none;
  margin-top: 2rem;
  color: aliceblue;
}
.queries {
  pointer-events: none;
  color: aliceblue;
  text-align: center;
}

.mail {
  font-size: 1.2rem;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 3rem 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .getStarted {
    margin-bottom: 1rem;
  }
  .hireMe {
    padding: 0 1rem;
  }

  .connectButton {
    margin-top: 1rem;
  }
}
